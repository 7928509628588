/* Font */
@font-face {
    font-family: "Pangram-Bold";
    src: url('/fonts/pangram/Pangram-Bold.ttf');
}
@font-face {
    font-family: "Pangram-Light";
    src: url('/fonts/pangram/Pangram-Light.ttf');
}
@font-face {
    font-family: "Pangram-Regular";
    src: url('/fonts/pangram/Pangram-Regular.ttf');
}
/* End of font */

/* Common */
h1, h2, h3, h4, h5, h6 {
    font-family: "Pangram-Bold";
}

h5 {
	font-size: 18px !important;
}

p, span, a {
    font-family: "Pangram-Regular";
}

.text-blog {
	font-family: "Pangram-Light";
	line-height: 1.75;
}

body {
    font-family: "Pangram-Regular";
    background-color: #F4F7FA !important;
    color: #737373 !important;
	padding: 0 !important;
}

/* Adjusting container width to span the HTML extend little higher. */

.container {
	max-width: 100% !important;
}

.container-with-padding {
	max-width: 82%;
	margin: 0 auto;
}

@media only screen and (max-width: 1000px) {

	.container-with-padding {
		max-width: 90%;
	}
}

@media only screen and (max-width: 576px) {
	
	.container {
		max-width: 100% !important;
	}

	.page-content {
		padding: 15px !important;
	}

	.container-with-padding {
		max-width: 95%;
	}
}

.container-with-background-image {
    background-image: url("/images/Inverse_Logo.png");
    background-color: #FFFFFF;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    min-height:94vh;
}

.child-container {
	background-color: #fff;
	border-left: 1px solid #eae9e9;
	border-right: 1px solid #eae9e9;
}

/* Navigation */

.navbar {
	padding: 0px 30px;
}

.navbar-nav .nav-link {
    font-family: "Pangram-Regular";
}

.header-logo {
	height: 50px;
    margin-right: 10px;
    margin-top: 8px;
}

.logo-caption {
    float: left;
    margin: 7px auto;
    display: block;
    color: #737373;
}

.logo-tagline {
    display: block;
    font-size: 14px;
}

.navbar {
	background: #fff;
}

.navbar .nav-item a {
	display: inline-block;
}

.navbar .nav-item i + a {
	padding-left: 0 !important;
    margin-left: 0 !important;
}

.navbar-wrapper {
    background-color: #fff;
	border-bottom: 1px solid #eae9e9;
	position: fixed;
	top: 0;
	width: 100%;
	height: 80px;
	z-index: 1000;
}

.nav-link {
    color: #737373;
}

.dropdown-item {
	background-color: #fff !important;
}

.nav-link.active {
    border-bottom: 2px solid #f79569;
}

.nav-link:hover {
    color: #f79569 !important;
    cursor: pointer;
}

.dropdown-toggle::after {
    vertical-align: middle !important;
}

.navbar-toggler-icon {
    background-image: url(/images/navbar-toggle.svg) !important;
}

.navbar-brand {
	margin-right: 0;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
	margin-top: 0;
}

#isYourCommitmentOpen .custom-control-label, #isUnplannedOpen .custom-control-label {
	font-size: 14px;
}

@media only screen and (min-width: 768px) {
	
	.navbar-expand-md .navbar-nav .nav-link {
		margin: 0px 20px;
		padding: 8px;
		width: fit-content;
	}
}
/* End of Navigation */

/* Footer */
footer {
    padding: 15px;
    background-color: #fff;
	border-top: 1px solid #eae9e9;
    width: 100%;
}

footer a {
    color: #737373;
}

footer p {
    margin-bottom: 0;
}
/* End Of Footer */

/* Spinner/Loader */
.spinner-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 2000;
    background: rgba(255, 255, 255, 0.70);
}

.spinner-gooey {
    position: absolute;
    z-index: 2001;
	top: 40%;
	left: 50%;
	width: 142px;
	height: 40px;
    margin: -20px 0 0 -71px;
}

.spinner-gooey .dot {
	position: absolute;
	width: 16px;
	height: 16px;
	top: 12px;
	left: 15px;
	background: #f79569;
	border-radius: 50%;
	transform: translateX(0);
	animation: dot 2.8s infinite;
}

.spinner-gooey .dots {
	transform: translateX(0);
	margin-top: 12px;
	margin-left: 31px;
	animation: dots 2.8s infinite;
}

.spinner-gooey .dots span {
	display: block;
	float: left;
	width: 16px;
	height: 16px;
	margin-left: 16px;
	background: #f79569;
	border-radius: 50%;
}

@-moz-keyframes dot {
	50% {
		transform: translateX(96px);
	}
}

@-webkit-keyframes dot {
	50% {
		transform: translateX(96px);
	}
}

@-o-keyframes dot {
	50% {
		transform: translateX(96px);
	}
}

@keyframes dot {
	50% {
		transform: translateX(96px);
	}
}

@-moz-keyframes dots {
	50% {
		transform: translateX(-31px);
	}
}

@-webkit-keyframes dots {
	50% {
		transform: translateX(-31px);
	}
}

@-o-keyframes dots {
	50% {
		transform: translateX(-31px);
	}
}

@keyframes dots {
	50% {
		transform: translateX(-31px);
	}
}
/* End of Spinner/Loader */

/* Floating save button */
.floating-bar {
	position:fixed;
	right:40px;
	z-index: 100;
    background: #ccc;
    padding: 20px;
}

.width-fit-content {
	width: fit-content !important;
}