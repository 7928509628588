.question-container{
    text-align:center;
    color:#fff;
}

.tips-section-title{
    font-size:17px;
    font-family:Pangram-Regular;
    line-height: 30px;
}

.tips {
    color: #fff !important;
}

.radio-container{
    font-size: 15px;
    padding: 20px 10px 25px 10px;
}

.matrix-choice-radio-container{
    font-size: 15px;
    padding: 15px 10px 15px 10px;
}

.tips-container{
    padding: 0 20px 0 20px;
}

.question-title {
    font-size:18px;
    font-family:Pangram-Regular;
    padding:25px;
    padding-bottom:10px;
    color: #737373;
}

.emostateAssessment input.input-radio {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #fff;
}

.emostateAssessment input.input-radio:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    margin: 3px auto;
    border-radius: 50%;
}

.emostateAssessment input.input-radio:checked:before {
    background: #AECF7A;
}

.emostateAssessment input.input-radio:checked {
    border-color:#fff;
}

.emostateAssessment input.input-radio-question {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    outline: none;
}

.emostateAssessment input.input-radio-question {
    border: 2px solid #1EB0D8 !important;
}

.emostateAssessment input.input-radio-question:before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    margin: 3px auto;
    border-radius: 50%;
}

.emostateAssessment input.input-radio-question:checked:before {
    background: #AECF7A;
}

.emostateAssessment input.input-radio-question:checked {
    border-color:#1EB0D8;
}

.emostateAssessment .tips-section{
    background-color: #1EB0D8;
    padding:20px;
    border-radius: 15px;
}

.questions-section{
    background-color: #f6f6f6;
    padding:0px !important;
    border-radius:15px;
    margin-top:30px;
}

.emostateAssessment .input-radio{
    margin-left:20px !important;
    margin-right:20px !important;
}

.radio-div{
    width:130px;
    display:inline-table;
}

.radio-div.first, .radio-div.last {
    width:130px !important;
}

.emostateAssessment .radio-caption{
    color:#1EB0D8
}

.submit-button {
    margin-top:30px;
    margin-bottom:20px;
    text-align:center;
}

.sub-button {
    border-radius:10px !important;
    padding: 0px !important;
    width: 180px;
    height: 40px;
    font-size:20px !important;
}

.sub-button:hover , .sub-button:focus {
    color:#fff !important;
}

.bottom-div {
    height:15px;
    border-bottom-left-radius:15px;
    border-bottom-right-radius:15px;
}

.emostateAssessment .sub-button {
    color: #fff;
    background-color: #1EB0D8;
}

.emostateAssessment .bottom-div {
    background-color:#1EB0D8;
}

.emostateAssessment .assessment-intro {
    background-color: #1EB0D8;
    padding:20px;
    margin: 0px 0 20px 0;
    border-radius: 15px;
}

.assessment-intro-title {
    text-align:center;
    font-family:Pangram-Bold;
    font-size:22px;
    color: #fff;
}

.assessment-intro-text {
    color: #fff;
    padding:10px 30px 10px 30px;
    text-align:justify;
    font-size:16px;
    line-height: 30px;
}

.assessment-intro-text b {
    color: #FFF !important;
}

.text-container {
    padding: 20px;
}

input.radioGroups {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #8a8781 ;
}

input.radioGroups:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin: 2px auto;
    border-radius: 50%;
}

input.radioGroups:checked:before {
    background: #AECF7A ;
}

input.radioGroups:checked {
    border-color:#8a8781 ;
}

.productivity-meter-report-section {
    background: #fab95e; 
    padding: 15px; 
    color: #fff; 
    border-radius: 8px;
    flex:1;
    min-width: 300px;
    box-sizing: border-box;
}

.risks-report-section {
    background: #92d6e1; 
    padding: 15px; 
    color: #fff; 
    border-radius: 8px;
    flex:1;
    min-width: 300px;
    box-sizing: border-box;
}

.growth-areas-report-section {
    background: #f9a576; 
    padding: 15px; 
    color: #fff; 
    border-radius: 8px;
    flex:1;
    min-width: 300px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1050px) {

    .radio-div{
        width: 100px;
    }

    .radio-div.first , .radio-div.last {
        width:100px !important;
    }
}

/* Media Query for mobile optimization */

@media only screen and (max-width: 620px) {

    .radio-div{
        width: 60px;
    }

    .radio-div.first , .radio-div.last {
        width:60px !important;
    }

    .radio-caption{
        font-size: 12px;
    }

    .assessment-intro {
        padding: 15px 20px !important;
    }

    .assessment-intro-title {
        font-size: 19px !important;
    }

    .assessment-intro-text {
        padding: 10px !important;
        font-size: 16px !important;
    }
}

/* For iPhone 6/7/8 devices */

@media only screen and (max-width: 520px) {

    .question-title {
        font-size: 15px;
    }

    .radio-container {
        padding-top: 10px;
    }

    .radio-div{
        width: 50px;
    }

    .radio-div.first , .radio-div.last {
        width:50px !important;
    }

    .desktop {
        display: none;
    }
}

@media only screen and (max-width: 410px) {

    .radio-div{
        width: 40px;
    }

    .radio-div.first , .radio-div.last {
        width:40px !important;
    }
}

/* For iPhone SE devices */

@media only screen and (max-width: 370px) {

    .radio-div{
        width: 35px;
    }

    .radio-div.first , .radio-div.last {
        width:35px !important;
    }
}