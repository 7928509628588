input:focus,
select:focus,
textarea:focus,
button:focus,
.custom-control-input:focus ~ .custom-control-label::before {
    outline: none !important;
    outline-width: 0 !important;
    border-color: #f79569 !important;
    box-shadow: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.btn-group-xs > .btn, .btn-xs {
    padding  : .5rem .5rem;
    font-size  : .825rem;
    line-height  : .5;
    border-radius : .2rem;
}

.table-curved {
    border-collapse: separate;
    border-spacing: 0px;
    border: 1px solid #d2d0d0;
    border-radius: 10px;
    border-left: 0px;
    width: 100%;
    overflow: auto;
}

.table-curved td, .table-curved th {
    border-left: 1px solid #d2d0d0;
    border-top: 1px solid #d2d0d0;
    padding: 10px
}

.table-curved th {
    border-top: none;
    background-color: #E9E9E9;
}

.table-curved th:first-child {
    border-radius: 10px 0 0 0;
}

.table-curved th:last-child {
    border-radius: 0 10px 0 0;
}

.table-curved th:only-child{
    border-radius: 10px 10px 0 0;
}

.table-curved tr:last-child td:first-child {
    border-radius: 0 0 0 10px;
}

.table-curved tr:last-child td:last-child {
    border-radius: 0 0 10px 0;
}

.vertical-center, .vertical-center td {
    vertical-align: middle !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
    background-color: #F79569 !important;
    color: #fff !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    /* width: 2.2rem !important;
    margin: 0.5rem !important; */
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
    background-color: #F79569 !important;
    color: #fff !important;
}

.react-datepicker-wrapper {
    margin-right: 10px;
}

.react-datepicker__close-icon::after {
    background-color: #F79569 !important;
}

.dragging-row {
    background-color: #fef7f1;
    background-color: transparent;
}

.dragging-row td {
    border-top: 1px solid #d2d0d0;
    border-bottom: 1px solid #d2d0d0;
    border-left: 1px solid #d2d0d0;
}

.dragging-row td:last-child {
    border-right: 1px solid #d2d0d0;
}

p.text-danger {
    padding-top: 5px;
    font-size: 15px;
}

.color-blue {
    color: #1EA1DB;
}

.color-primary {
    color: #f79569;
}

.color-green {
    color: green;
}

.custom-radio-group, .custom-checkbox-group {
	display: flex;
	flex-wrap: wrap;
}

.custom-checkbox-group .checkbox-item-container,
.custom-radio-group .radio-item-container {
	display: flex;
	align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
	margin-top: 8px;
	margin-right: 15px;
}
.custom-checkbox-group .checkbox-item-container label,
.custom-radio-group .radio-item-container label {
	margin-left: 5px;
	margin-bottom: 0px;
}

.custom-checkbox-group input[type='checkbox']{
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 25%;
    outline: none;
    border: 2px solid #d2d2d2;
    cursor: pointer;
}

.custom-radio-group input[type='radio'] {
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    outline: none;
    border: 2px solid #d2d2d2;
    cursor: pointer;
}

.custom-checkbox-group input[type='checkbox']:focus,
.custom-radio-group input[type='radio']:focus {
    border: 2px solid #d2d2d2 !important;
}

.custom-radio-group input[type='radio']:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    margin: 3px auto;
    border-radius: 50%;
}

.custom-checkbox-group input[type='checkbox']:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    margin: 2px auto;
    border-radius: 25%;
}

.custom-checkbox-group input[type='checkbox']:checked:before,
.custom-radio-group input[type='radio']:checked:before {
    background: #f79569;
}

.custom-checkbox-group input[type='checkbox']:checked,
.custom-radio-group input[type='radio']:checked {
    border-color:#d2d2d2;
}

.thrive-meter-section {
    background: #fab95e;
    padding: 12px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 15px;
}

.thrive-meter-btn {
    color: #f9a576 !important;
    border: 1px solid #f9a576 !important;
    font-weight: bold !important;
}

.energy-state-section {
    background: #AECF7A;
    padding: 12px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 15px
}

.energy-state-btn {
    color: #acd076 !important;
    border: 1px solid #acd076 !important;
    font-weight: bold !important;
}

.values-section {
    background: #92d6e1;
    padding: 12px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 15px
}

.values-btn {
    color: #92d6e1 !important;
    border: 1px solid #92d6e1 !important;
    font-weight: bold !important;
}

.emo-state-section {
    background: #f9a576;
    padding: 12px;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 15px
}

.emo-state-btn {
    color: #f9a576 !important;
    border: 1px solid #f9a576 !important;
    font-weight: bold !important;
}

.border-green {
    border: 1px solid green !important;
}

.reset-cursor {
    cursor: initial !important;
}

.bg-white {
    background: #fff;
}

.remove-border {
    border: none;
}

.remove-bg {
    background: none;
}

.table-action-button {
    background: none;
    border: none;
    padding: 0 15px;
}

.archive-action-button {
    width: auto;
    color: rgb(242, 149, 105);
    font-size: 12px;
    padding: 2px 5px;
    margin-top: 5px;
    border-radius: 3px;
    border: none;
    text-align: center;
    box-shadow: 0 1px 4px 0 rgb(32 33 36 / 12%);
    background: white;
    min-width: 30px;
}

.action-button {
    background: none;
    border: none;
    /* padding: 0 15px; */
    width: 30px;
    text-align: center;
    box-shadow: 0 1px 4px 0 rgb(32 33 36 / 12%);
    border-radius: 5px;
    background: white;
    min-width: 30px;
    margin: 1px;
}

div.action-button-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

div.compass-action-button-cell {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.loaderComponent {
    text-align: center;
    vertical-align: middle;
    height:80vh;
}

.loaderContainer {
    position:relative;
    top:50%;
}

.loaderComponent img {
    width:40px;
    height:40px;
}

#errorMsg {
    color: red;
}

.buttonLoader {
    width:22px;
    height:22px;
    margin: 20px 5px;
}

h1, h2, h3, h4, h5, h6 {
    color: #737373 !important;
}

.page-content {
    padding: 20px;
    padding-bottom: 70px;
    min-height: 94vh;
}

.section-container {
    background-color: #fff;
}

.inner-container {
    padding: 15px;
}

.form-container {
    padding: 15px;
}

.row-section-container {
    margin: 0px;
}

.custom-link-style {
    background: none !important;
    border: none !important;
    color: #f79569 !important;
    font-family: "Pangram-Bold";
}

.inner-container-header {
    box-shadow: 0 2px 10px 0 rgba(204, 204, 204, 0.5);
    margin-bottom: 20px;
    padding: 10px 0;
}

.bread-crumb {
    margin: 0px 15px 15px 15px;
    background-color: #f6f6f6;
    padding: 10px 12px;
    border-radius: 8px;
    font-size: 15px;
}

.bread-crumb a {
    color: #737373;
}

.grey-container {
    background-color: #f6f6f6;
    padding: 10px 12px;
    border-radius: 8px;
    font-size: 15px;
}

.section-title {
    border-bottom: 1px solid #ebedf2;
    margin-bottom: 15px;
    padding: 8px 0;
}

.btn-primary {
    background-color: #f79569 !important;
    border-color: #f79569 !important;
}

.btn-primary:hover {
    background: none !important;
    border-color: #FBB116 !important;
    color: #FBB116 !important;
}

.btn-primary:disabled {
    background-color: #f79569 !important;
    border-color: #f79569 !important;
    color: #fff !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #f6f6f6;
    opacity: 1;
}

.form-control::placeholder, .public-DraftEditorPlaceholder-inner {
    color: #b2b2b2;
    font-family: 'Pangram-Light';
    font-size: 14px;
}

.collapse-container {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
}

.collapse-title {
    font-size: 18px;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    background-color: rgba(0, 0, 0, 0.03);
    margin-bottom: 0;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.export-button {
    background: none;
    border: none;
}

.export-button a {
    color: #464D69;
    border: 1px solid #EBEDF2;
    border-radius: 0.2rem;
    color: #464D69;
    padding: 4px 8px;
    margin: 0 10px;
}

.export-button a:hover {
    text-decoration: none;
    border: 1px solid #f79569;
    color: #f79569;
}
/* End of common */

.body-section {
    width: 100%;
    background: #fff;
    padding-top: 10px;
}

/* Login Form */
.login-form-section {
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    margin: 0 auto;
    margin-top: 40px;
    border: 1px solid #a7a7a7;
}

.login-form-section a {
    color: #737373;
    text-decoration-line: underline;
}

.login-form-section input {
    background-color: #a7a0a014;
    height: 45px;
}

.login-form-section .form-group {
    width: 80%;
    margin: auto;
}

.form-group {
    margin-bottom: 1.5rem !important;
}

.signin-heading {
    margin-bottom: 10px;
}
/* End of login form */

/* React table */
.customCheckATE {
    opacity: 0 !important;
    position: absolute;
    width: 100%;
    left: 0;
    height: 25px;
}

@media only screen and (min-width: 992px) {
    .body-section {
        padding: 75px 0;
    }
    .login-form-section {
        width: 50%;
    }
}

/* Adjust boostrap navbar breakpoints */

@media only screen and (max-width: 1000px) {
    .navbar {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    .navbar-expand-md .navbar-collapse {
        display: block;
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        margin: 10px;
    }
    .collapse:not(.show) {
        display: none !important;
    }
    .navbar-expand-md {
        justify-content: space-between !important;
    }
    .navbar-expand-md .navbar-toggler {
        display: flex !important;
    }
    .navbar-expand-md .navbar-nav{
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        margin-bottom: 10px;
        list-style: none;
        margin-left: 0 !important;
        width: 100%;
        border: 2px solid #d2d0d0;
        background-color: #f9f9f9;
        border-radius: 10px;
    }
    .navbar-expand-md .navbar-nav .nav-link{
        margin: 0 !important;
    }
    .dropdown-menu {
        border-radius: 10px;
        border: 2px solid #d2d0d0;
        background-color: #f9f9f9;
    }
    .dropdown-item {
        background-color: #f9f9f9 !important;
        padding-left: 10px !important;
    }
    .dropdown-item .nav-link {
        padding-left: 1.5rem !important;
    }
}

@media only screen and (max-width: 576px) {
    .navbar {
        padding: 0px 5px !important;
    }
    .signin-heading {
        padding-top: 25px;
    }
}

.htmlEditor {
    border: 1px solid #ced4da;
    border-radius: 4px;
    padding: 5px 10px;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #f79569;
    background-color: #f79569;
}

.checkbox-wrapper {
    background-color: #f6f6f6;
    padding: 2px 8px;
    border-radius: 2px;
}

.btn-right {
    float: right;
}

.draggable-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
}

.time-block-card {
    /* background-color: rgb(224, 224, 224);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
    width: 140px;
    height: 140px;
    /* border: 1px solid #f79569; */
    border: 3px solid #f9ded1;
    border-radius: 10px;
    /* padding: 5px; */
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: grab;
    background: #FFF5ED;
    color: #737373;
    overflow: hidden;
    position: relative;
    display: flex;
}

.time-block-card-sub-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.time-block-card-sub-section p.mb-2.font-bold{
    padding: 5px;
}

.time-block-card p.pb-1, .time-block-card p.pt-1 {
    font-size: 10px;
    margin: 0px;
    font-family: 'Pangram-light';
    padding: 2px 5px !important;
    /* word-break: break-word;
    width: 90px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}
.time-block-card p.pt-1.border-top {
    border-top: 1px solid #f9ded1 !important;
}
.time-block-card p.mb-2.font-bold{
    /* word-break: break-all; */
    font-size: 12px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    height: 62px;
}


.font-bold {
    font-family: 'Pangram-Bold' !important;
}

.compass-btn.btn.btn-secondary:disabled,
.todays-btn.btn.btn-secondary:disabled,
.tracker-your-commitments .btn-secondary,
.weekly-plan-day-btn.btn-secondary,
.pbh-small-btn-default {
    background-color: #fff !important;
    border-color: #f79569 !important;
    color: #f79569 !important;
    font-size: 12px;
}

.compass-btn.btn.btn-secondary:disabled {
    cursor: not-allowed;
    opacity: 1 !important;
}

.weekly-plan-day-btn.btn-secondary:active .sub-title-light-text {
    color: white;
    font-size: 14px;
    font-family: 'Pangram-Light';
    padding-right: 5px;
}

.compass-btn.btn.btn-secondary,
.todays-btn.btn.btn-secondary,
.tracker-your-commitments .btn-secondary.active,
.pbh-small-btn-active.btn.btn-secondary,
.pbh-small-btn-active.btn.btn-secondary:focus,
.pbh-small-btn-active.btn.btn-secondary:active,
.weekly-plan-day-btn-active.btn.btn-secondary,
.weekly-plan-day-btn.btn-secondary:focus ,
.weekly-plan-day-btn.btn-secondary:active
{
    background-color: #f79569 !important;
    border-color: #f79569 !important;
    color: #fff !important;
    font-size: 12px;
}

div.tracker-calendar-toolbar,
div.weekly-plan-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
    padding: 10px;
    /* background-color: #E9E9E9; */
}

div.tracker-calendar-toolbar h6 {
    margin-bottom: 0;
}

.space-between-section, 
.popup-footer-section {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.justify-content-center {
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.popup-footer-wrapper {
    background-color: #FFF5ED;
    padding: 10px;
    padding-top: 5px;
    margin-left: -1rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
    border-top: 2px solid #f9ded1;
}

i.fa-bell,
i.fa-bell-o,
i.fa-clock-o,
i.fa-calendar-check-o,
i.fa-calendar.active {
    font-size: 18px;
    color: #f29569;
}

i.fa-pencil.active {
    /* font-size: 12px; */
    color: #f29569;
}

i.fa-pencil {
    /* font-size: 12px; */
    /* color:darkgray; */
}

.dd-option-text {
    color: gray;
    font-size: 14px;
    font-family: 'Pangram-Regular';
    padding: 10px;
}

.dd-option-text:active, .dd-option-text:hover {
    color: #f79569;
}

.small-title-text {
    color: gray;
    font-size: 14px;
    font-family: 'Pangram-Regular';
    padding-right: 5px;
}

.sub-title-light-text,
.light-title-text {
    color: gray;
    font-size: 14px;
    font-family: 'Pangram-Light';
    padding-right: 5px;
}

.popup-status-bar {
    background: #f9f9f9;
    border-radius: 6px;
    padding: 10px;
    padding-bottom: 1px;
    padding-top: 5px;
    font-family: 'Pangram-Light';
    margin-bottom: 15px;
}

.toolbarClassName {
    background-color: #f6f6f6 !important;
}

.badge-planned-event {
    background-color: #AECF7A !important;
    color: #fff !important;
    font-size: 10px;
    padding: 5px;
}

.badge-unplanned-event {
    background-color: #f07f71 !important;
    color: #fff !important;
    font-size: 10px;
    padding: 5px;
}

.ur-commitments-filter-header {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: baseline;
    /* background: #f3f3f3; */
    padding: 8px 0px;
    margin-top: 1rem;
    min-width: 200px;
}

.time-tracking-status-section {
    border: 1px solid;
    border-radius: 12px;
    border-color: #e9e9e9;
    padding: 0px 10px;
    margin-bottom: 15px;
}

.time-tracking-row {
    padding: 0px;
    padding-bottom: 2px;
    padding-top: 5px;
}

.animate-bell{
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 4s .7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
}

  @-webkit-keyframes ring {
    0% { -webkit-transform: rotateZ(0); }
    1% { -webkit-transform: rotateZ(30deg); }
    3% { -webkit-transform: rotateZ(-28deg); }
    5% { -webkit-transform: rotateZ(34deg); }
    7% { -webkit-transform: rotateZ(-32deg); }
    9% { -webkit-transform: rotateZ(30deg); }
    11% { -webkit-transform: rotateZ(-28deg); }
    13% { -webkit-transform: rotateZ(26deg); }
    15% { -webkit-transform: rotateZ(-24deg); }
    17% { -webkit-transform: rotateZ(22deg); }
    19% { -webkit-transform: rotateZ(-20deg); }
    21% { -webkit-transform: rotateZ(18deg); }
    23% { -webkit-transform: rotateZ(-16deg); }
    25% { -webkit-transform: rotateZ(14deg); }
    27% { -webkit-transform: rotateZ(-12deg); }
    29% { -webkit-transform: rotateZ(10deg); }
    31% { -webkit-transform: rotateZ(-8deg); }
    33% { -webkit-transform: rotateZ(6deg); }
    35% { -webkit-transform: rotateZ(-4deg); }
    37% { -webkit-transform: rotateZ(2deg); }
    39% { -webkit-transform: rotateZ(-1deg); }
    41% { -webkit-transform: rotateZ(1deg); }
    43% { -webkit-transform: rotateZ(0); }
    100% { -webkit-transform: rotateZ(0); }
  }
  
  @-moz-keyframes ring {
    0% { -moz-transform: rotate(0); }
    1% { -moz-transform: rotate(30deg); }
    3% { -moz-transform: rotate(-28deg); }
    5% { -moz-transform: rotate(34deg); }
    7% { -moz-transform: rotate(-32deg); }
    9% { -moz-transform: rotate(30deg); }
    11% { -moz-transform: rotate(-28deg); }
    13% { -moz-transform: rotate(26deg); }
    15% { -moz-transform: rotate(-24deg); }
    17% { -moz-transform: rotate(22deg); }
    19% { -moz-transform: rotate(-20deg); }
    21% { -moz-transform: rotate(18deg); }
    23% { -moz-transform: rotate(-16deg); }
    25% { -moz-transform: rotate(14deg); }
    27% { -moz-transform: rotate(-12deg); }
    29% { -moz-transform: rotate(10deg); }
    31% { -moz-transform: rotate(-8deg); }
    33% { -moz-transform: rotate(6deg); }
    35% { -moz-transform: rotate(-4deg); }
    37% { -moz-transform: rotate(2deg); }
    39% { -moz-transform: rotate(-1deg); }
    41% { -moz-transform: rotate(1deg); }
    43% { -moz-transform: rotate(0); }
    100% { -moz-transform: rotate(0); }
  }
  
  @keyframes ring {
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
  }

.mb-2-min-btn-width {
    min-width: 108px;
}

.card.custom-card {
    border: 1px solid #d2d0d0;
    border-radius: 8px;
}

.card.custom-card > .card-body {
    padding-bottom: 0;
}

#weekly-review-floating-tool-bar .week-label {
    min-width: 230px;
}

.sticky-toolbar {
    padding: 20px 37px 10px 35px;
    background-color: #fff;
    box-shadow: 0 1px 4px 0 rgb(32 33 36 / 12%);
    /* border-bottom: 1px solid #eae9e9; */
    position: fixed;
    top: 73px;
    left: 0;
    width: 100%;
    height: 65px;
    z-index: 999;
}

.open-ended-prompt-container {
    margin-bottom: 0;
    text-align: center;
    padding: 8px;
    border: 1px solid #ced4da;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #E9E9E9;
}

.open-ended-prompt-response-container {
    border: 1px solid #ced4da;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.open-ended-prompt-response-container .editorClassName {
    margin: 5px;
}


#compassEditableTbl .ReactTable {
    overflow-x: auto;
}

#compassEditableTbl .ReactTable .rt-table .rt-thead,
#compassEditableTbl > .ReactTable > .rt-table >  .rt-tbody
{
    min-width: 1100px !important;
}

#compassEditableTbl .rt-thead.-header, 
#compassEditableTbl .ReactTable .rt-th {
    background-color: #f79569;
    color: white;
}

.compass-cell{
    display: flex;
    height: 100%;
    min-height: 100px;
}

.compass-cell > div.editable-text {
    background-color: #fff;
    padding: 8px;
    font-size: 12px;
    flex-grow: 1;
    height: 100%;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    border-radius: 5px;
    border: 1px solid #cccccc;
}

.editable-text div {
    outline: none;
    pointer-events: auto;
}
.editable-text {
    position: relative;
    height: 100%;
}

.cell-text-editor {
    height: 100%;
}

#compass-focus-area-select{
    height: 100%;
}
.css-yk16xz-control {
    background: no-repeat;
    border: none;
    height: 100%;
}

#compass-focus-area-select > .css-1wa3eu0-placeholder {
    text-decoration: underline;
}

.css-yk16xz-control .css-1hb7zxy-IndicatorsContainer, #compass-focus-area-select > .css-yk16xz-control > .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

#compass-focus-area-select > .css-yk16xz-control > .css-g1d714-ValueContainer > .css-1uccc91-singleValue,
#compass-focus-area-select > .css-yk16xz-control > .css-g1d714-ValueContainer > .css-1wa3eu0-placeholder {
    width: 92%;
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
}

.compass-header-cell {
    padding: 10px 0px;
}

#compassEditableTbl .ReactTable, .rt-table {
    border-radius: 8px;
}

.compass-custom-save-btn {
    border-radius: 0;
    border-left: 1px solid #fff !important;
    border-right: 1px solid #fff !important;
}

#compassEditableTbl,
#compassEditableTbl .ReactTable, 
#compassEditableTbl .ReactTable .rt-thead
{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

#compass-focus-area-select >  .css-5nas8e-control > .css-1wy0on6 > .css-1okebmr-indicatorSeparator,
#compass-focus-area-select >  .css-1dn74tc-control > .css-1wy0on6 > .css-1okebmr-indicatorSeparator,
#compass-focus-area-select >  .css-5nas8e-control > .css-1hb7zxy-IndicatorsContainer > .css-1okebmr-indicatorSeparator,
#compass-focus-area-select >  .css-1dn74tc-control > .css-1hb7zxy-IndicatorsContainer > .css-1okebmr-indicatorSeparator {
    width: 0px !important;
}

#compass-focus-area-select >  .css-5nas8e-control > .css-1wy0on6 > div.css-tlfecz-indicatorContainer,
#compass-focus-area-select >  .css-1dn74tc-control > .css-1wy0on6 > div.css-tlfecz-indicatorContainer,
#compass-focus-area-select >  .css-5nas8e-control > .css-1hb7zxy-IndicatorsContainer > .css-1gtu0rj-indicatorContainer,
#compass-focus-area-select >  .css-1dn74tc-control > .css-1hb7zxy-IndicatorsContainer > .css-tlfecz-indicatorContainer {
    border: 1px solid hsl(0, 0%, 80%) !important;
    border-right: none !important;
    padding: 6px;
}

#compassEditableTbl > .ReactTable .rt-resizer {
    right: 0px !important;
    width: 10px;
}

#compassEditableTbl .ReactTable .rt-td {
    overflow: initial;
    overflow-y: visible !important;
}

#compassEditableTbl .ReactTable .rt-tbody {
    overflow: initial;
    overflow-y: visible !important;
}

#compassEditableTbl .ReactTable {
    overflow-x: auto;
    overflow-y: visible !important;
}

#compassEditableTbl .ReactTable .rt-table {
    overflow: initial;
    overflow-y: visible !important;
}

#compassEditableTbl .ReactTable .rt-table {
    background: white;
}

.__react_component_tooltip {
    max-width: 300px;
    padding: 8px !important;
}

.event-info-timer {
    vertical-align: middle;
    font-size: 12px;
    border-radius: 5px;
    width: fit-content;
    /* background-color: #f6f6f6;
    border: 1px solid #e9e9e9; 
    padding: 2px 5px; */
    padding-left: 3px;
}

.event-info-wrapper {
    /* background-color: #fff5ed; 
    border: 2px solid #f9ded1; */
    background-color: #f6f6f6; 
    border: 1px solid #e9e9e9;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.unplanned-event-legend {
    height: 10px;
    width: 10px;
    background-color: #f07f71;
    display: inline-block;
}

.planned-event-legend {
    height: 10px;
    width: 10px;
    background-color: #AECF7A;
    display: inline-block;
}

.real-estate-income-planner .input-group-text {
    width: 42px;
}